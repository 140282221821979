<template>
  <div>
    <h5 class="info-text">{{ $t('choosevpsuser') }}</h5>

    <div class="row justify-content-center">
      <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;" v-if="alert_me">
        {{ alert_msg }}
      </div>

      <div class="col-lg-10">
        <div class="row">

          <autocomplete :source="users" @selected="pickedUser" @clear="clear"></autocomplete>

          <div class="mt-3" v-if="full_name && wallet">
            <b>{{ $t('nametxt') }}:</b> {{ full_name }} <br>
            <b>{{ $t('billing') }}:</b> ${{ wallet }}
          </div>

        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import Autocomplete from 'vuejs-auto-complete'

  export default {
    components: {
      Autocomplete
    },
    data() {
      return {
        users: [],
        model: {
          user: null
        },
        alert_me: false,
        alert_msg: null,
        alert_class: 'text-danger',
        full_name: '',
        wallet: '',
      }
    },
    methods: {
      loadUsers(resp) {
        this.users = [];
        for(var i=0;i < resp.data.length;i++) {
          var user = resp.data[i].userid;

          this.users.push({
            'id': user,
            'name': user,
            'full_name': resp.data[i].first_name + ' ' + resp.data[i].last_name,
            'wallet': resp.data[i].wallet_final
          });
        }
      },
      clear() {
        this.full_name = ''
        this.wallet = ''
      },
      pickedUser(user) {
        this.model.user = user.value;
        this.full_name = user.selectedObject.full_name;
        this.wallet = user.selectedObject.wallet;
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      validate() {
        return this.$validator.validateAll().then(res => {
          if (this.model.user === null) {
            this.alert_me = true;
            this.alert_msg = this.$t('mustchoosevpsuser');
            res = false;
          }
          else {
            this.alert_me = false;
          }
          this.$emit('on-validated', res, this.model);
          return res
        })
      }
    },
    mounted() {
      const failop = error => {
        console.log(error);
      };
      // Get VPSes for user
      this.$listUsers().then(this.loadUsers, failop);
    }
  }
</script>
<style lang="scss">

</style>
