<template>
  <div>
    <div class="row justify-content-center">

      <div class="col-lg-10">

        <div class="card">
          <div class="card-body table-responsive table-full-width">
            <el-table :data="tablePaymentInstruction">
                <el-table-column :label="method" property="name" width="100px"></el-table-column>
                <el-table-column :label="instructionus">
                  <template slot-scope="scope">
                    <span v-html="scope.row.instructionus"></span>
                  </template>
                </el-table-column>
                <el-table-column :label="instructioneur">
                  <template slot-scope="scope">
                    <span v-html="scope.row.instructioneur"></span>
                  </template>
                </el-table-column>
            </el-table>

          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  //import {Tabs, TabPane, Card} from 'src/components/UIComponents'
  import {Table, TableColumn} from 'element-ui'
  Vue.use(Table)
  Vue.use(TableColumn)

  export default {
    components: {

    },
    data() {
      return {
        method: this.$t('paymentmethod'), instructions: this.$t('instructions'),
        instructionus: this.$t('instructionus'), instructioneur: this.$t('instructioneur'),
        tablePaymentInstruction: [
          { name: 'Neteller', instructionus: this.$t('neteller_usinstructions'), instructioneur: this.$t('neteller_eurinstructions') },
          { name: 'Paypal', instructionus: this.$t('paypalinstructions'), instructioneur: this.$t('paypalinstructions') },
          { name: 'Skrill', instructionus: this.$t('skrillinstructions'), instructioneur: this.$t('skrillinstructions') },
          { name: this.$t('bankwire'), instructionus: this.$t('bankwire_usinstructions'), instructioneur: this.$t('bankwire_eurinstructions') }
        ],
        model: { paymentRead: true }
      }
    },
    methods: {
      validate() {
        return this.$validator.validateAll().then(res => {
          this.$emit('on-validated', res, this.model)
          return res
        });
      }
    }
  }
</script>
<style>
</style>
