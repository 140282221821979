<template>
  <div>
    <h5 class="info-text"> {{ $t('choosevpslocation') }} </h5>
    <div class="row justify-content-center">

      <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;" v-if="alert_me">
        {{ alert_msg }}
      </div>

      <div class="col-lg-10">
        <div class="row" v-for="location in locations">

          <div class="col-sm-4" v-for="loc in location">
            <icon-checkbox v-model="vpsloc[loc.code]"
                           :icon="loc.icon" :title="loc.title" @input="locChosen">
            </icon-checkbox>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import {IconCheckbox} from 'src/components/UIComponents'

  const perrow = 3;

  export default {
    components: {
      IconCheckbox
    },
    data() {
      return {
        locations: [],
        vpsloc: null,
        chosenloc: null,
        model: {
          location: null,
          paymentRead: true
        },
        alert_me: false,
        alert_msg: null,
        alert_class: 'text-danger'
      }
    },
    methods: {
      loadLocations(resp) {
        if (!resp.success) {
          return;
        }
        let data = resp.data;

        let veeplocs = {};
        let locationcount = data.length;
        let tmploc = [];
        this.locations = [];
        for (var i=0;i < locationcount;i++) {
          let loc = data[i];
          let loc_icon = "fa fa-globe";

          // if (i > 0 && (i % perrow) == 0) {
          //   this.locations.push(tmploc);
          //   tmploc = [];
          // }

          tmploc.push({
            icon: loc_icon,
            title: loc['description'],
            code: loc['code']
          });

          veeplocs[loc['code']] = false;
        }
        // After the main loop
        if (tmploc.length > 0) {
          this.locations.push(tmploc);
        }
        this.vpsloc = Object.assign({}, veeplocs);
        //console.log(this.vpsplan);
      },
      locChosen(e) {
        var keys = Object.keys(this.vpsloc);
        for(var i=0;i < keys.length;i++) {
          var k = keys[i];
          if (this.vpsloc[k]) {
            if (this.chosenloc === null) {
              this.chosenloc = k;
              break;
            }
            else {
              if (this.chosenloc !== k) {
                this.vpsloc[this.chosenloc] = false;
                this.chosenloc = k;
              }
            }
          }
        }
        //console.log(this.locations);
        // Finalize the Plan chosen
        if (this.chosenloc !== null) {
          for (var ix=0;ix < this.locations.length;ix++) {
            let locidx = this.locations[ix].findIndex(l => this.chosenloc == l.code);
            if (locidx >= 0) {
              this.model.location = this.locations[ix][locidx];
              break;
            }
          }
        }
        //console.log(this.model);
        this.alert_me = false;
      },
      validate() {
        return this.$validator.validateAll().then(res => {
          if (this.model.location === null) {
            this.alert_me = true;
            this.alert_msg = this.$t('mustchoosevpslocation');
            res = false;
          }
          this.$emit('on-validated', res, this.model)
          return res
        });
      }
    },
    mounted() {
      const failop = error => {
        console.log(error);
      };
      // Get VPSes for user
      this.$getVPSLocations().then(this.loadLocations, failop);
    }
  }
</script>
<style>
</style>
